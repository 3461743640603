// Body
$body-bg: #ffffff;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$light: #f8f9fa;
$dark: #343a40;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$danger: $red;

$primary: #bea24e;
$secondary: black;

$bgcHeader: black;
$bgcFooter: black;

$fzH1: 30px;
$fzH2: 28px;
$fzH3: 26px;
$fzH4: 24px;
$fzH5: 22px;
$fzH6: 20px;

$colorTitle: black;
$colorText: black;
$colorLink: $primary;
$colorDiscrete: #a7a7a7;
$colorDiscreteLight: #f7f7f7;

$swiperThemeColor: $primary;

$fzText: 18px;

$ffTitle: "open_sans", Arial, sans-serif;
$ffText: "open_sans", Arial, sans-serif;
$ffItalic: "open_sans", Arial, sans-serif;
$ffBold: "open_sans", Arial, sans-serif;

$widthContainer: 1190px;

$borderRadius: 10px;

$mobile: '(max-width: 767px)';
$phone-portrait: '(max-width: 575px)';
$phone-landscape: '(min-width: 576px) and (max-width: 767px)';
$tablet-portrait: '(min-width: 768px) and (max-width: 991px)';
$tablet-landscape: '(min-width: 992px) and (max-width: 1199px)';
$large-desktop: '(min-width: 1200px) and (max-width: 1919px)';
$semi-desktop1: '(min-width: 1200px) and (max-width: 1439px)';
$semi-desktop2: '(min-width: 1440px) and (max-width: 1919px)';
$wide-desktop: '(min-width: 1920px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)';
