.formImmo {
	@media (min-width: 576px) {
		margin-top: 0;
		transform: translateY(-50%);
	}

	@media (max-width: 575px) {
		margin-top: -2rem;
	}

	&-frame {
		border-top-left-radius: 0;
	}

	&-btn {
		all: unset;
		background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='search' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' viewBox='0 0 512 512'%3E%3Cpath d='M206.1 412c45.8 0 90.2-15.3 126.3-43.4l136.2 136.2c10.2 9.8 26.4 9.5 36.2-0.6c9.6-9.9 9.6-25.7 0-35.6L368.6 332.3c69.8-89.8 53.5-219.2-36.3-289s-219.2-53.5-289 36.3s-53.5 219.2 36.3 289C115.8 396.7 160.3 412 206.1 412L206.1 412z M96.6 96.6c60.4-60.4 158.4-60.4 218.9 0s60.4 158.4 0 218.9s-158.4 60.4-218.9 0c0 0 0 0 0 0c-60.4-60-60.8-157.6-0.8-218.1C96.1 97.1 96.4 96.8 96.6 96.6L96.6 96.6z' fill='rgb(#{colorRgb($primary)})'%3E%3C/path%3E%3C/svg%3E");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		width: rem(36px);
		height: rem(36px);

		@media (min-width: 576px) {
			margin-left: rem(45px);
			margin-right: rem(45px);
		}

		@media (max-width: 575px) {
			margin-left: 15px;
		}
	}
}

.homeimmo {
	&-tab {
		@include colSize(100%);
	}

	&-frame {
		padding: 30px 15px;
	}

	&-nav {
		position: relative;
		z-index: 1;
		@include colSize(100%, false);

		@media (max-width: 575px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&-tabs {
		border: none;
		margin: 0;
	}

	&-item:not(:last-child) {
		margin-right: 8px;
	}

	&-tabs &-link {
		border-radius: rem($borderRadius) rem($borderRadius) 0 0;
		background-color: rgba(#fff, .7);
		font-weight: bold;
		line-height: 1.2;
		text-transform: uppercase;
		color: $secondary;
		border: none;

		@media (min-width: 576px) {
			font-size: rem(30px);
			padding:  .23em 3.2em;
		}

		&.active {
			border: none;
			background-color: #fff;
			color: $secondary;
		}
	}
}
