.chiffres {
	padding-top: rem(90px);
	padding-bottom: rem(90px);
	background-color: $colorDiscreteLight;

	&-row {
		justify-content: center;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: center;
		}
	}

	@include title($color: #fff);

	&-block {
		padding-top: 15px;
		padding-bottom: 15px;
		display: flex;
		align-items: center;

		@media (max-width: 767px) {
			flex-direction: column;
		}
	}

	&-icon {
		height: 65px;
		width: 65px;
		fill: $primary;

		@media (min-width: 768px) {
			margin-right: 19px;
		}
	}

	&-text {
		flex: 1 1 0;

		@media (max-width: 1199px) {
			br {
				display: none;
			}
		}

		@media (max-width: 767px) {
			text-align: center;
		}
	}
}
