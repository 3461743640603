.formImmo {
	$parent: &;
	position: relative;
	z-index: 5000;

	@media (min-width: 576px) {
		margin-top: rem(90px);
	}

	@media (max-width: 575px) {
		margin-top: 45px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&-btnCollapse {
		@media (min-width: 576px) {
			display: none;
		}
	}

	&-collapse {
		@media (max-width: 575px) {
			display: none;
		}
	}

	&-frame {
		@include frame;
		background-color: #fff;
		align-items: center;
		font-size: rem(16px);
		padding: rem(40px) rem(48px);

		&:not(.homeimmo-frame) {
			@media (max-width: 575px) {
				margin-bottom: 25px;
				margin-top: 40px;
				padding: 0;

				&, &:hover, &:focus  {
					box-shadow: none;
				}
			}
		}
	}

	&-row {
		align-items: flex-end;
		margin-top: -15px;
		margin-bottom: -15px;
	}

	&-block1 {
		@include colAuto;
	}

	&-block2 {
		@media (min-width: 992px) {
			@include colSize(20%);
		}

		@media (max-width: 991px) {
			@include colSize(100%);
		}
	}

	&-col {
		display: flex;
		flex-direction: column;
		padding-top: rem(15px);
		padding-bottom: rem(15px);

		@media (min-width: 992px) {
			@include colAuto(rem(15px));
		}

		@media (max-width: 991px) AND (min-width: 576px) {
			&:not(#{$parent}-colLast) {
				@include colSize(50%, rem(15px));
			}
		}

		@media (max-width: 575px) {
			@include colSize(100%, rem(15px));
		}
	}

	&-colLast {
		@media (max-width: 991px) {
			@include colSize(100%);
		}
	}

	.bootstrap-select {
		@extend select;
	}

	.show > .btn-light.dropdown-toggle {
		all: unset;
	}

	.bootstrap-select > .dropdown-toggle {
		&, &:not(:disabled):not(:disabled):hover, &:not(:disabled):not(:disabled):focus, &:not(:disabled):not(:disabled):active {
			all: unset;
			display: flex;
			align-items: center;
			width: 100%;
			background-color: transparent;
			border: none;
			outline: none !important;
			box-shadow: unset !important;
		}

		&::after {
			content: none;
		}
	}
}
