// actu dans la boucle de la liste ou widget
.actualite {
    $parent: &;

    &-actu {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &-link {
        @include frame($hover: true);
        background-color: white;
        flex: 1 1 0;
        padding: rem(20px) rem(30px);
        height: 100%;
        display: flex;
        flex-direction: column;

		&, &:hover, &:focus, &:active {
			color: $colorText;
			text-decoration: none;
		}
	}

	&-img {
		max-height: rem(180px);
		margin-bottom: 6px;
		border: 1px solid rgb(205, 205, 205);
        object-fit: cover;
	}

    &-date {
        strong {
            font-style: normal;
            background-color: $primary;
            font-size: rem(14px);
            line-height: 1.286;
            color: #fff;
            padding: .5em .7em;
            display: inline-block;
            font-weight: 400;
        }
    }

    &-entry {
        display: block;
        padding-bottom: 15px;
    }

    & &-newsTitle {
        display: block;
        font-weight: 400;
        font-size: rem($fzText);
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;
        text-transform: none;
        font-weight: 400;

        &::after {
            content: none;
        }
    }

	&-text {
		font-size: rem(14px);
		line-height: 1.714;
	}

	&-btn {
		display: flex;
		justify-content: flex-end;
		align-items: center;
        margin-top: auto;
        font-size: rem(14px);
        line-height: 1.714;
	}

	&-icon {
        height: 1em;
		width: 1em;
		margin-left: .35em;
	}
}
