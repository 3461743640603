.prestations {
	padding-top: rem(115px);
	padding-bottom: rem(115px);
	position: relative;

	&-row {
		padding-bottom: rem(100px);
		justify-content: center;
	}

	@include title;

	&-block {
		display: flex;
		color: $colorText;
		padding-top: 15px;
		padding-bottom: 15px;

		&:hover, &:focus, &:active {
			color: $colorText;
			text-decoration: none;
		}
	}

	&-frame {
		@include frame($hover: true);
		padding: rem(20px);
		position: relative;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		flex: 1 1 0%;

		&::before, &::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&::before {
			height: 100%;
			width: 100%;
			opacity: .5;
			background-size: cover;

			@media (min-width: 576px) {
				background-position: center;
			}

			@media (max-width: 575px) {
				background-position: center top;
			}
		}

		@for $i from 1 through 3 {
			&#{$i}::before {
				background-image: url(../../../images/home/prestation-#{$i}.jpg);
			}
		}

		&::after {
			width: calc(100% - #{rem(16px)});
			height: calc(100% - #{rem(16px)});
			border-radius: rem($borderRadius);
			border: 1px solid $primary;
		}
	}

	&-text {
		position: relative;
		z-index: 1;
		margin-top: 1rem;
		margin-bottom: 1rem;

		strong {
			font-size: rem(30px);
		}

		p {
			font-size: rem(16px);
		}
	}

	&-icon {
		position: relative;
		z-index: 1;
		fill: black;
		height: rem(26px);
		width: rem(26px);
		margin-top: auto;
	}
}
