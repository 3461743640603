#menu {
}

.primaryMenu {
    $parent: &;
    padding-left: 0;
    padding-right: 0;

    &-toggler {
        border: none;
        
        @media (min-width: 992px) {
            display: none;
        }

        @media (max-width: 991px) {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-top: 15px;
        }
    }

    &-list {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        justify-content: flex-end;
    }

    &-item {
        &:hover, &:focus, &:active, .active {
            #{$parent}-link::before {
                left: 0;
                right: 0;
            }
        }

        &:last-child {
            #{$parent}-link {
                padding-right: 0;
            }
        }
    }

    &-link {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $primary;
        text-transform: uppercase;
        font-weight: 700;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: .5rem;
            left: 50%;
            right: 50%;
            height: 1px;
            background-color: $primary;
            transition: left .3s ease-out, right .3s ease-out;
        }
    }
}
