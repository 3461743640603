@use "sass:math";
@use "sass:color";

$html-font-size: 16px;
@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
    $calc: stripUnit($pxValue / $html-font-size);
    @return #{$calc}rem;
}

@function color-yiq($color) {
    $r: red($color);
    $g: green($color);
    $b: blue($color);

    $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

    @if ($yiq >= 150) {
        @return #000;
    } @else {
        @return #fff;
    }
}

@function colorRgb($color) {
    @return '#{color.red($color)}, #{color.green($color)}, #{color.blue($color)}';
}
