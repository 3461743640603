.footer {
    box-shadow: 0px rem(-40px) rem(80px) 0px rgba(0, 0, 0, 0.1);

    &-top {
        color: #fff;
        background: $bgcFooter;
        padding-top: rem(30px);
        padding-bottom: rem(45px);
    }

    &-contact {
        @media (max-width: 767px) {
            text-align: center;
        }
    }

    &-logo {
        max-width: 175px;

        @media (max-width: 767px) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 15px;
        }
    }

    &-company {
        text-transform: uppercase;
        font-weight: 700;
        font-size: rem(22px);
        margin-bottom: 1em;
        display: inline-block;
    }

    &-tel {
        color: $primary;
        font-size: rem(25px);
        font-weight: 700;
    }

    &-reseaux {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @media (min-width: 768px) {
            align-items: flex-end;
        }

        @media (max-width: 767px) {
            margin-top: rem(40px);
            align-items: center;
        }
    }

    &-link {
        margin-top: rem(40px);
        font-size: rem(16px);
    }

    &-sub {
        padding-top: 15px;
        padding-bottom: 15px;
        background: $primary;
        text-align: center;

        &, a {
            color: #000;
        }
    }
}
