html {
    font-size: $html-font-size;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    @media (max-width: 991px) {
        font-size: rem($html-font-size * .8);
    }
}

body {
    margin: 0;
    padding: 0;
    text-align: left;
    background: $body-bg;
    font-family: $ffText;
    color: $colorText;
    font-size: rem($fzText);
    --swiper-theme-color: #{$swiperThemeColor};
    --swiper-pagination-bullet-size: #{rem(13px)};
    --swiper-pagination-bullet-horizontal-gap: 2px;
    --swiper-pagination-bullet-inactive-color: #a7a7a7;
    --swiper-pagination-bullet-inactive-opacity: 1;
}

.hidden {
    display: none;
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: $widthContainer;
    }
}

body:not(.home) {
    h1, h2, h3, h4, h5, h6 {
        padding: 0;
        font-weight: 700;
        line-height: 1.2;
        font-family: $ffTitle;
        color: $colorTitle;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 1.05em;

        &::after {
            content: "";
            background-color: $primary;
            height: 2px;
            width: 14%;
            position: absolute;
            bottom: -.3em;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

$listStyles: ("h1": $fzH1, "h2": $fzH2, "h3": $fzH3, "h4": $fzH4, "h5": $fzH5, "h6": $fzH6);

@each $element, $size in $listStyles {
    #{$element} {
        font-size: rem($size);
    }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="week"],
input[type="month"],
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
optgroup,
select,
textarea,
.form-control {
    &:not(.mandatory) {
        appearance: none;
        height: 100%;
        border-radius: 0;
        border: none;
        background-color: transparent;
        padding: 5px 0;
        border-bottom: 1px solid rgb(205, 205, 205) !important;
        display: flex;
        align-items: flex-end;
        flex: 1 0 0%;
        width: 100%;
        max-width: 100%;
        position: relative;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            color: inherit;
        }
    }
}

.input-col {
    margin-top: rem(20px);
    margin-bottom: rem(20px);
}

.form-rgpd {
    font-size: rem(14px);
}

.submit-container {
    display: flex;
    justify-content: flex-end;
}

.form-submit {
    margin-top: 5px;
}

select {
    background-repeat: no-repeat;
    background-size:  1em;
    background-position: 100% 60%;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 240.8 240.8' style='enable-background:new 0 0 240.8 240.8%3B' xml:space='preserve'%3E%3Cg%3E%3Cpath id='Expand_More' d='M220.1 57.7l-99.7 99.7L20.7 57.7c-4.8-4.8-12.4-4.8-17.2 0c-4.7 4.8-4.7 12.5 0 17.2l108.3 108.3l0 0l0 0c4.7 4.8 12.4 4.8 17.2 0L237.3 74.9c4.7-4.8 4.7-12.5 0-17.2C232.5 52.9 224.8 52.9 220.1 57.7z'/%3E%3C/g%3E%3C/svg%3E");
    padding-right: 1.2em !important;
    cursor: pointer;
}

.form-control:focus {
    border-color: rgba($primary, .25);
    box-shadow: 0 0 0 0.2rem rgba($primary, .25);
}

ul, ol {
    margin: 10px;
    padding: 0;
    list-style-position: inside;
}

a {
    transition: all 0.2s linear;
    color: $colorLink;

    &:hover {
        color: $colorLink;
    }

    &:focus {
        outline: 0;
    }

    img {
        border: 0;
    }

    // &[href$=".pdf"] {
    //     padding: 10px 0 10px 30px;
    //     background: url(/img/pdf.png) no-repeat 0 50% transparent;
    // }
}

blockquote {
    margin: 30px 0;
    padding: 5px 15px;
    border-left: 4px solid #eee;
    font-style: italic;
}

em {
    font-style: italic;
}

strong {
    font-weight: 700;
}

.overflow {
    overflow: hidden;
}

.scrollToTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000000;
    display: none;

    &-icon {
        fill: $primary;
        width: 35px;
        height: 35px;
    }
}

ol.breadcrumb {
    margin: 20px 0;
    background: none;
    padding: 0;
    font-size: rem(16px);

    a {
        color: #a7a7a7;
    }
}

body:not(.home) #content {
    padding-bottom: rem(100px);
}

/* #CONTENT
================================================== */

/* ------ mentions légales -----
#mentions-bloc { font-size: 0.8em; color: #000; width: 440px; position: fixed; left: 50%; margin-left: -220px; top: 50px; background: #fff; border: 10px solid #eee; padding: 10px; border-radius: 6px; display: none; box-shadow: 0 0 2px #000; z-index: 100000; }
@media (max-width: 767px) {
#mentions-bloc { width: 400px; font-size: 0.7em; margin-left: -200px}
}
#close_mentions { width: 20px; height: 20px; cursor: pointer; position: absolute; top: -5px; right: -5px; background: url(../img/close_mentions.png) no-repeat transparent; text-indent: -9999px; }

#mentions-bloc {
max-width: 550px;
border-radius: 4px;
color: #000;
font-size: 0.8em;
transform: translateY(-50px);
transition: all .33s;
}
*/

.fancybox-slide--current #hidden-content-b {
    transform: translateY(0);
}

/* ----- NOTIFICATIONS -----*/

.result_error {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #e57c7c;
}

.result_success {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #78ca71;
}

#flashMessage {
    background: #fff;
    color: #337ab7;
    line-height: 40px;
    text-align: center;
}

// Connexion
#tableau_login { overflow:hidden; }

#compte { margin: 20px auto}

#UserSiteLoginForm div, #UserAddUserForm div { margin: 0 0 10px; }

.error-message { color: #a94442}

// Contact
.accept {
    display: inline;
}

.error {
    .form-control {
        border-color: $danger;
    }
    .control-label {
        color: $danger;
    }
}

.mandatory {
    height: 0.1px;
    width: 0;
    border: 0 !important;
    padding: 0 !important;
}

.mentionRGPD {
    font-size: rem(14px);
}

#map {
    background: $gray-200;
    width: 100%;
    height: 600px;
    color: #000;
}

/* ----- COOKIE BAR ----- */

#cookie-bar {
    background: #111111;
    height: auto;
    line-height: 24px;
    color: #eeeeee;
    text-align: center;
    padding: 3px 0;
    font-size: 0.8em;
    z-index: 999;

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        &.bottom {
            bottom: 0;
            top: auto;
        }
    }

    p {
        margin: 0;
        padding: 0;
    }

    a {
        color: #ffffff;
        display: inline-block;
        border-radius: 3px;
        text-decoration: none;
        padding: 0 6px;
        margin-left: 8px;
    }

    .cb-enable {
        background: #3ac976;

        &:hover {
            background: #009900;
        }
    }

    .cb-disable {
        background: #990000;

        &:hover {
            background: #bb0000;
        }
    }

    .cb-policy {
        background: #0033bb;

        &:hover {
            background: #0055dd;
        }
    }
}

/* ----- DEMO ASSETS ----- */

.bs-component {
    .modal {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        width: 90%;
    }
}

.modal-title {
    margin-top: 0;
}

/* ----- Message de consentement RGPD ----- */

.mentionRGPD {
    font-size: 0.8em;
}

// pagination
.pagination {
    justify-content: center;
}

.page {
    &-item {
        &.active {
            background-color: $primary;

            .page-link {
                background-color: $primary;
                border-color: $primary;
            }
        }
    }

    &-link {
        &, &:hover {
            color: $primary;
        }

        &:focus {
            box-shadow: none;
        }
    }
}

// Surclasses les boutons Bootstrap
$btns:
"primary" $primary,
"secondary" $secondary,
"success" $green,
"danger" $red,
"warning" $yellow,
"info" $cyan,
"light" $light,
"dark" $dark;

@each $modifier, $colorBtn in $btns {
    .btn-#{$modifier} {
        @include bouton($hover: false, $color: $colorBtn);

        &:not(:disabled):not(.disabled) {
            &:hover, &:focus, &:active {
                @include hoverBouton($color: $colorBtn);
            }
        }
    }

    .btn-outline-#{$modifier} {
        @include boutonOutline($hover: false, $color: $colorBtn);

        &:not(:disabled):not(.disabled) {
            &:hover, &:focus, &:active {
                @include hoverBoutonOutline($color: $colorBtn);
            }
        }
    }
}

.btn-primary {
    color: #fff;

    &:not(:disabled):not(.disabled) {
        &:hover, &:focus, &:active {
            color: #fff;
        }
    }
}

.btn-outline-primary {
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus, &:active {
            color: #fff;
        }
    }
}

.btn-sm {
    font-size: rem($fzText * .65);
}

.btn-lg {
    font-size: rem($fzText * 1.2);
}

.alert {
    @include frame;
}

.champsRequis {
    font-size: rem(14px);
}

::selection {
  background-color: $primary;
}

.partcheck {
    font-size: rem(14px)
}
