@use "sass:color";

@mixin respond-to($media) {
    @media only screen and #{$media} {
        @content;
    }
}

@mixin colAuto($pd: 15px) {
    flex: 1 0 0%;
    width: 100%;
    max-width: 100%;

    @if $pd {
        padding-left: $pd;
        padding-right: $pd;
    }
}

@mixin colSize($size, $pd: 15px) {
    flex: 0 0 auto;
    width: $size;
    max-width: 100%;

    @if $pd {
        padding-left: $pd;
        padding-right: $pd;
    }
}

@mixin frame($hover: false) {
    border-radius: rem($borderRadius);
    box-shadow: 0px rem(40px) rem(80px) 0px rgba(0, 0, 0, 0.1);
    transition: all .3s ease-out;
    @content;

    @if($hover) {
        &:hover, &:focus  {
            box-shadow: 0px rem(20px) rem(40px) 0px rgba(0, 0, 0, 0.2);
            @content;
        }

        &:active {
            box-shadow: rgb(50 50 93 / 25%) 0px rem(30px) rem(60px) rem(-12px) inset, rgb(0 0 0 / 30%) 0px rem(18px) rem(36px) rem(-18px) inset !important;
        }
    }
}

@mixin bouton($hover: true, $color: $primary) {
    color: color-yiq($color);
    text-decoration: none;
    line-height: 1.2;
    font-weight: bold;
    padding: .7em 2em;
    border-width: 0;
    display: inline-block;
    text-align: center;
    outline: none;
    background-color: $color;
    font-size: rem($fzText);
    text-transform: uppercase;

    @include frame($hover: true);

    @if $hover {
        &:hover, &:focus, &:active {
            @include hoverBouton($color);
        }
    }
}

@mixin hoverBouton($color: $primary) {
    color: color-yiq($color);
    outline: none;
    background-color: $color;
    text-decoration: none;
    cursor: pointer;
}

@mixin boutonOutline($hover: true, $color: $primary) {
    color: $color;
    text-decoration: none;
    line-height: 1.2;
    font-weight: bold;
    padding: .7em 2em;
    border: 1px solid $color;
    display: inline-block;
    text-align: center;
    outline: none;
    background-color: transparent;
    font-size: rem($fzText);
    text-transform: uppercase;

    @include frame($hover: true);
}

@mixin hoverBoutonOutline($color: $primary) {
    color: color-yiq($color);
    outline: none;
    background: $color;
    border-color: $color;
    text-decoration: none;
    cursor: pointer;
}

@mixin title($parent: &, $color: #f5f5f5) {
    #{$parent}-headerTitle {
		text-align: center;
		text-transform: uppercase;
		display: block;
		line-height: 1.2;
        font-size: rem(25px);
		font-weight: 300;
		color: $primary;
		position: relative;
		z-index: 1;



		&::before {
            $titleFz: 80px;
			content: attr(data-content);
			position: absolute;
			z-index: -1;
			top: 15%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: $color;
			text-align: center;
			font-weight: 800;
			text-transform: none;
            pointer-events: none;
            width: 100%;

            @media (min-width: 576px) {
                font-size: $titleFz;
            }

            @media (max-width: 575px) {
                font-size: $titleFz * .7;
            }
		}
	}

	#{$parent}-title {
		font-size: rem(30px);
		font-weight: bold;
		color: $secondary;
		text-align: center;
        margin-top: 0;
        margin-bottom: rem(60px);
        position: relative;
        z-index: 1;

        @media (max-width: 768px) {
            br {
                display: none;
            }
        }
	}
}

@mixin favoriteIcon ($color1, $color2) {
    $color1: colorRgb($color1);
    $color2: colorRgb($color2);
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' viewBox='0 0 512 448'%3E%3Cpath d='M364 0c-40.9 0-80 17-108 46.9c-55.9-59.6-149.5-62.7-209.2-6.8C17 68 0 107.1 0 148c0 147.4 237.7 292 248 297.7 c5.1 3 11.4 3 16.5 0C274.3 440 512 295.4 512 148C511.9 66.3 445.7 0.1 364 0z' fill='rgb(#{$color1})'%3E%3C/path%3E%3Cpath d='M480 148c0 115.3-182.6 238.6-224 265.1C214.6 386.7 32 263.4 32 148C32.1 83.9 84 32 148.1 32.1 c37.7 0 73.1 18.4 94.8 49.3c5.1 7.2 15.1 9 22.3 3.9c1.5-1.1 2.8-2.4 3.9-3.9C305.8 28.9 378.2 16.2 430.6 53 C461.6 74.7 480 110.2 480 148z' fill='rgb(#{$color2})'%3E%3C/path%3E%3C/svg%3E");
}
