.header {
    position: relative;

    &-top {
        background: $bgcHeader;
        position: relative;
        z-index: 1;

        @at-root body:not(.home) & {
            padding-bottom: 15px;
    		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
        }
    }

    &-row {
        align-items: flex-start;
        justify-content: space-between;
    }

    &-logo {
        padding-top: 20px;
        @include colSize(218px);

        @media (max-width: 575px) {
            @include colSize(100%);
            text-align: center;
        }

        img {
            max-width: 175px;
        }
    }

    &-right {
        padding-top: 20px;
        flex-direction: column;
        align-items: flex-end;
        padding-left: 15px;
        padding-right: 15px;

        @media (max-width: 575px) {
            @include colSize(100%, false);
            padding-bottom: 20px;
        }
    }

    .rs-list {
        justify-content: flex-end;
        margin: 0 -5px;
    }

    .rs-item {
        margin: 0 5px;
    }

    .rs-icon {
        width: 17px;
        height: 17px;
    }

    &-moduleNav {
        padding-top: rem(20px);
    }

    &-link {
        font-size: rem(16px);
        padding-left: rem(20px);
        padding-right: rem(20px);

        &, &:hover, &:focus, &:active {
            color: #fff;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &-linkFavorite {
        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: 1em;
            width: 1em;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            @include favoriteIcon(white, black);
        }
    }

    &-icon {
        height: 1em;
        width: 1em;
        fill: #fff;
    }

    &-container {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-rows: auto 1fr;
    }
}

.topBar {
    display: flex;
    flex-wrap: wrap;
    line-height: 3em;
    justify-content: space-between;
}
