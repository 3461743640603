.annonces {
	&-count {
		font-size: rem(14px);
		color: $colorDiscrete;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	&-list {
		margin-top: -15px;
		margin-bottom: -15px;
	}

	&-item {
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
	}

	&-link {
		@include frame(true);
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
		width: 100%;

		&:hover, &:active, &:focus {
			text-decoration: none;
		}
	}

	& &-img {
		height: rem(260px);
		object-fit: cover;
		object-position: center;
		width: 100%;
	}

	& &-imgNone {
		object-fit: contain;
		height: rem(260px);
		width: 100%;
		background-color: #000;
	}

	&-text {
		padding: rem(15px) rem(20px) rem(20px);
		font-size: rem(16px);
		line-height: 1.2;
	}

	& &-title {
		all: unset;
		font-weight: 700;
		color: #000;
		text-transform: uppercase;

		&::after {
			content: none;
		}
	}

	&-town {
		display: block;
		font-size: rem(14px);
		color: $colorDiscrete;
		text-transform: capitalize;
	}

	&-price {
		color: $primary;
		font-weight: 700;
		font-size: rem(20px);
		padding-top: rem(10px);
		display: block;
	}

	&-favorites {
		all: unset;
		position: absolute;
		top: rem(35px);
		right: rem(32px);
		z-index: 1;
		line-height: 1;
		height: rem(21px);
		width: rem(21px);

		&::before, &::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&::before {
			z-index: -1;
			border-radius: 100%;
			width: calc(100% + #{rem(10px)});
			height: calc(100% + #{rem(10px)});
			background-color: #fff;
		}

		&::after {
			content: "";
			height: 100%;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			transition: background-image .2s;

			@include favoriteIcon(#868686, white);
		}

		&--active::after, &:hover::after {
			@include favoriteIcon($primary, $primary);
		}
	}

	&-mapTab {
		margin-top: rem(112px);
		margin-bottom: rem(50px);
		height: rem(600px);
	}

	&-map {
		height: 100%;
		@include frame;

		.leaflet-popup-content-wrapper {
			border-radius: 0;
			box-shadow: none;
			background-color: transparent;
		}

		.leaflet-popup-content {
			margin: 0;
		}
	}

	&-mapLink {
		min-width: rem(250px);
		background-color: #fff;
	}

	&-mapImg {
		height: rem(160px);
	}
}

.leaflet-popup-tip-container {
	bottom: 0;
}
