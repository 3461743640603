.rs {
    $parent: &;

    &-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        list-style-type: none;
        margin: 0 rem(-11px);
    }

    &-item {
        display: flex;
        margin: rem(11px);
    }

    &-link {
        display: inline-flex;

        &:active,
        &:focus,
        &:hover {
            #{$parent}-icon {
                fill: darken($primary, 15%);
            }
        }
    }

    &-icon {
        width: rem(42px);
        height: rem(42px);
        transition: transform .5s, fill .5s;
        fill: $primary;
        vertical-align: text-top;
    }
}
